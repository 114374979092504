import React from 'react';
import {
    Button, Form, FormField, FormGroup, Header, Input,
} from 'semantic-ui-react';
import Layout from '../components/layout';
import Helmet from '../components/Helmet';
import * as DefaultTemplate from '../components/ContentPages/DefaultTemplate';


const UnsubscribePage = () => (
    <Layout
        showDisclosures={false}
    >
        <Helmet
            title="Email Unsubscribe"
            description="Opt out of DebtConsolidation emails."
            robots="noindex, follow"
        />
        <DefaultTemplate.Wrapper>
            <DefaultTemplate.Body>
                <Header as="h1" size="large">
                    Email Unsubscribe
                </Header>
                <p>
                    Enter your email address below to be unsubscribed from all future DebtConsolidation.com
                    emails.
                </p>
                <Form size="small">
                    <FormGroup>
                        <FormField width={8}>
                            <label htmlFor="email">Email</label>
                            <Input type="email" name="email" id="email" />
                        </FormField>
                    </FormGroup>
                </Form>
                <br />
                <Button.Group>
                    <Button
                        icon="lock"
                        labelPosition="left"
                        color="green"
                        content="Submit Now"
                        type="button"
                    />
                </Button.Group>
            </DefaultTemplate.Body>
        </DefaultTemplate.Wrapper>
    </Layout>
);

export default UnsubscribePage;
